<ng-container *ngIf="template$ | async as template">
  <transect-nx-flyover-modal
    [contentTemplate]="template"
    [isOpen]="isOpen$ | async"
    [minWidth]="minWidth$ | async"
    [maxWidth]="maxWidth$ | async"
    [shouldCloseOnBackdropClick]="shouldCloseOnBackdropClick$ | async"
    (isOpenChange)="handleOpenChange($event)"
    (backdropClick)="handleBackdropClicked()"
  >
  </transect-nx-flyover-modal>
</ng-container>

import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';

export const MagicLinkDTO = z.object({
  _id: z.string().uuid(),
  expires_at: DateSchema,
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  key: z.string(),
  role: z.string(),
  email: z.string().email(),
  report__id: z.string().uuid(),
  creator__id: z.string().uuid(),
});

export type MagicLinkDTO = z.infer<typeof MagicLinkDTO>;

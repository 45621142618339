import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { FlyoverModalStateService } from './flyover-modal-state.service';

@Component({
  selector: 'transect-nx-flyover-modal',
  templateUrl: './flyover-modal.component.html',
  styleUrls: ['./flyover-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FlyoverModalStateService],
})
export class FlyoverModalComponent implements OnChanges, OnDestroy {
  @Input() isOpen = false;
  @Input() minWidth?: string;
  @Input() maxWidth?: string;
  @Input() shouldCloseOnBackdropClick = true;
  @Input() contentTemplate: TemplateRef<HTMLElement> | null = null;

  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() backdropClick = new EventEmitter();

  constructor(private renderer: Renderer2) {}

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.hasOwnProperty('isOpen')) {
      return;
    }
    if (changes['isOpen'].currentValue === true) {
      this.renderer.addClass(document.body, 'modal-open');
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
    }
  }

  handleBackdropClicked() {
    this.backdropClick.emit();
  }
}

<div
  *ngIf="shouldCloseOnBackdropClick"
  (click)="handleBackdropClicked()"
  [class.open]="isOpen"
  class="flyover-backdrop"
></div>
<div
  [class.open]="isOpen"
  class="flyover-container"
  [ngStyle]="{ minWidth: minWidth, maxWidth: maxWidth }"
>
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</div>

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ConfigCatService } from '../services/config-cat.service';
import { AuthService } from '../services/auth.service';

interface ConfigCatGuardData {
  configCatFeature: {
    flag: string;
    redirectOn?: boolean;
    redirectTo?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ConfigCatGuard implements CanActivate {
  constructor(
    private configCatService: ConfigCatService,
    private authService: AuthService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.userObserver$.pipe(
      take(1),
      switchMap(() =>
        this.keyToFeatureFlag(
          (route.data as ConfigCatGuardData).configCatFeature.flag
        )
      ),
      map((result) => {
        if (!(route.data as ConfigCatGuardData).configCatFeature.redirectOn) {
          if (!result) {
            return false;
          } else {
            return true;
          }
        } else {
          if (!result) {
            return this.router.parseUrl(
              (route.data as ConfigCatGuardData).configCatFeature.redirectTo
            );
          } else {
            return true;
          }
        }
      })
    );
  }

  private keyToFeatureFlag(key: string) {
    switch (key) {
      case 'minireports':
        return this.configCatService.miniReports;
      case 'landownerContacts':
        return this.configCatService.landownerContact$;
      case 'bulkProjectCreation':
        return this.configCatService.bulkProjectCreation$;
      case 'projectSiteComparison':
        return this.configCatService.projectSiteComparison$;
      default:
        throw new Error('Mapping not yet implemented.');
    }
  }
}

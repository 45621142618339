export enum ElectricGeneratorDataCategory {
  PLANNED = 'planned',
  OPERATING = 'operating',
  CANCELED_POSTPONED = 'canceled_postponed',
}

export enum ElectricGeneratorDataTechnology {
  SOLAR = 'solar',
  WIND = 'wind',
}

import { Inject, Injectable } from '@angular/core';
import {
  FormTypeKey,
  TransectPlanKey,
} from '@transect-nx/data-transfer-objects';
import { Observable, of } from 'rxjs';
import {
  map,
  shareReplay,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { FormUserFreeTrialAgreementService } from '../modules/form-user-free-trial-agreement/form-user-free-trial-agreement.service';
import { FormTypesService } from './backend-api/form-types.service';
import { FormsApiService } from './backend-api/forms.service';
import { SessionClaimsService } from './session-claims.service';
import { FormStateTokenMap } from './transect-form-state-factory.service';
import { TransectFormStateService } from './transect-form-state.service';

@Injectable({
  providedIn: 'root',
})
export class FreeTrialAgreementService {
  constructor(
    private sessionClaimsService: SessionClaimsService,
    private formModalService: FormUserFreeTrialAgreementService,
    private formTypesService: FormTypesService,
    private formsApiService: FormsApiService,
    @Inject(FormStateTokenMap[FormTypeKey.FREE_TRIAL_AGREEMENT])
    private transectFormStateService: TransectFormStateService
  ) {}

  formType$ = this.formTypesService
    .getFormTypeByKey(FormTypeKey.FREE_TRIAL_AGREEMENT)
    .pipe(shareReplay(1));

  form$ = this.formType$.pipe(
    switchMap((formType) =>
      this.formsApiService.getFormByFormTypeId(formType._id)
    )
  );

  checkFreeTrialAgreement(): Observable<boolean> {
    return this.sessionClaimsService.isTransectPlan$(TransectPlanKey.Free).pipe(
      take(1),
      switchMap((isFreeUser) => {
        if (!isFreeUser) {
          return of(true);
        }
        return this.form$.pipe(
          withLatestFrom(this.formType$),
          switchMap(([form, formType]) => {
            if (!form || !form.completed_on) {
              return this.formModalService
                .open({
                  formTypeId: formType._id,
                  stateService: this.transectFormStateService,
                })
                .pipe(map(() => true));
            }
            return of(true);
          })
        );
      })
    );
  }
}

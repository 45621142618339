import {
  FullscreenOverlayContainer,
  OverlayContainer,
} from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';
import { QuillModule } from 'ngx-quill';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './error-handlers/global-error-handler';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HttpInternalServerErrorInterceptor } from './interceptors/http-internal-server-error.interceptor';
import { VersionInterceptor } from './interceptors/version.interceptor';
import { AppFlyoverModule } from './modules/app-flyover/app-flyover/app-flyover.module';
import { ForcedUpdateDialogModule } from './modules/forced-update-dialog/forced-update-dialog.module';
import { FormCeiiNdaModule } from './modules/form-ceii-nda/form-ceii-nda.module';
import { FormUserFreeTrialAgreementModule } from './modules/form-user-free-trial-agreement/form-user-free-trial-agreement.module';
import { UnrecoverableStateDialogModule } from './modules/unrecoverable-state-dialog/unrecoverable-state-dialog.module';
import { UpgradePromptModule } from './modules/upgrade-prompt/upgrade-prompt.module';
import { rollbarFactory, RollbarService } from './services/rollbar.service';
import { FormUserFreeTrialProvider } from './services/transect-form-state-factory.service';

declare global {
  interface Window {
    analytics: {
      track: (eventName: string, options?: object) => void;
      identify: (id: string, options?: object) => void;
      page: () => void;
    };
    hj: any;
    pendo: any;
    pdfReady: boolean;
    // HubSpot hsConversationsSettings
    hsConversationsSettings: {
      identificationToken: string;
      identificationEmail: string | undefined;
    };
    hsConversationsOnReady: (() => void)[];
    HubSpotConversations: {
      clear: (params: { resetWidget: boolean }) => void;
      widget: {
        open: () => void;
        remove: () => void;
        refresh: () => void;
      };
    };
  }
}

@NgModule({
  imports: [
    FormCeiiNdaModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    UnrecoverableStateDialogModule,
    ForcedUpdateDialogModule,
    UpgradePromptModule,
    AppFlyoverModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['align'],
          ['link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          ['clean'],
        ],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production || environment.staging,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    FormUserFreeTrialAgreementModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInternalServerErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VersionInterceptor,
      multi: true,
    },
    {
      provide: 'TransectAnalysticsService1',
      useFactory: (i: any) => i.get('TransectAnalytics'),
      deps: ['$injector'],
    },
    {
      provide: 'TransectRollbarService1',
      useFactory: (i: any) => i.get('TransectRollbar'),
      deps: ['$injector'],
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: 'googleTagManagerId',
      useValue: environment.GTM_ID,
    },
    {
      provide: 'googleTagManagerAuth',
      useValue: environment.GTM_AUTH,
    },
    {
      provide: 'googleTagManagerPreview',
      useValue: environment.GTM_ENV,
    },
    CookieService,
    DatePipe,
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    FormUserFreeTrialProvider,
  ],
  bootstrap: [AppComponent],
  declarations: [AppComponent],
})
export class AppModule {}

import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import {
  CustomMapLayersUploadAccess,
  CustomerMapAccess,
} from '../customer.dto';

export const CreateCustomerBody = z.object({
  billing_notes: z.string().nullable(),
  chargify_customer_id: z.number().nullable(),
  domain: z.string().nullish(),
  industry__id: z.string().uuid().optional().nullable(),
  name: z.string(),
  hubspot_customer_id: z.string().nullable(),
  subscription_active: z.boolean(),
  vision_plan__id: z.string().uuid(),
  terms_accepted: z.boolean().optional(),
  subscription_start_date: DateSchema.nullable().optional(),
  subscription_end_date: DateSchema.nullable().optional(),
  pilot_start_date: DateSchema.nullable().optional(),
  pilot_end_date: DateSchema.nullable().optional(),
  pilot_states: z.array(z.string()).optional(),
  marketplace_discount_pct: z.number().optional(),
  subscription_custom_map_layers_upload: z
    .nativeEnum(CustomMapLayersUploadAccess)
    .default(CustomMapLayersUploadAccess.OFF)
    .optional(),
});

export type CreateCustomerBody = z.infer<typeof CreateCustomerBody>;

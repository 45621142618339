import { z } from 'zod';

export enum FormTypeKey {
  SITE_ACCESS_AUTHORIZATION = 'form_marketplace_site_access_authorization',
  USER_QUESTIONNAIRE = 'form_marketplace_user_questionnaire',
  MARKETPLACE_AGREEMENT = 'marketplace_agreement',
  ALTA_SCOPE_OF_WORK = 'form_marketplace_alta_scope_of_work',
  PHASE1_ESA_AMENDMENT = 'form_marketplace_phase1esa_amendment',
  TESS_SCOPE_OF_WORK = 'form_marketplace_tess_scope_of_work',
  FREE_TRIAL_AGREEMENT = 'form_user_free_trial_agreement',
  LANDOWNER_CONTACT_TOC = 'form_landowner_contact_toc',
  CEII_NDA = 'form_ceii_nda',
}

export const FormTypeDTO = z.object({
  _id: z.string().uuid().optional().nullable(),
  key: z.nativeEnum(FormTypeKey),
  name: z.string().nullish(),
  description: z.string().nullish(),
});

export type FormTypeDTO = z.infer<typeof FormTypeDTO>;

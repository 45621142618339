import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AppConstants } from '../../shared/app.constants';
import { EventStreamService } from '../../services/event-stream.service';

@Component({
  selector: 'ts-upgrade-prompt',
  templateUrl: './upgrade-prompt.component.html',
  styleUrls: ['./upgrade-prompt.component.scss'],
})
export class UpgradePromptComponent implements OnInit {
  readonly scheduleDemoLink = AppConstants.SCHEDULE_DEMO_URL;

  contactSalesLink: string | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title?: string },
    private dialogRef: MatDialogRef<UpgradePromptComponent>,
    private eventStream: EventStreamService
  ) {}

  ngOnInit(): void {
    const subject = encodeURIComponent('Moving forward from my free trial');
    const body = encodeURIComponent(
      `Hi, I've explored the Transect app via the free trial and I have questions about next steps.`
    );

    this.contactSalesLink = `mailto:sales@transect.com?subject=${subject}&body=${body}`;

    this.eventStream
      .publish('Upgrade Prompt', 'view', {
        label: 'free tier',
      })
      .subscribe();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  handleRequestUpgradeClick(): void {
    window.open(this.scheduleDemoLink, '_blank');
  }
}

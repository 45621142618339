<transect-nx-dialog-modal
  title="CEII NDA"
  [footer]="true"
  [footerTemplate]="footerTemplate"
  [contentTemplate]="contentTemplate"
>
</transect-nx-dialog-modal>
<ng-template #contentTemplate>
  <div
    class="scrollable-content"
    tsDetectScrollToEnd
    (scrollEnd)="handleScrollToEnd()"
  >
    <div class="content-container">
      <div class="agreement-content text-shades-black">
        <div class="mb-20">
          <span class="title-medium">CEII NONDISCLOSURE ADDENDUM</span>
        </div>
        <div>
          <span class="body-medium"
            >This CEII Nondisclosure Addendum (“Addendum”) reflects the terms
            between the user/organization approving this Addendum (“Client”) and
            Transect, Inc. (“Transect”). Transect and Client are also each
            referred to as a “Party” and collectively as the “Parties.” In
            consideration of the mutual promises set forth in this Addendum, the
            adequacy and sufficiency of which are hereby acknowledged, the
            Parties agree as follows: </span
          >
        </div>

        <div>
          <span class="body-medium">1) </span>
          <span class="label-large">ADDENDUM TO AGREEMENT. </span>
          <span class="body-medium"
            >This Addendum is a supplement to the terms and conditions of the
            Subscriber Agreement (“Agreement”) previously entered into by the
            Parties.</span
          >
        </div>
        <div>
          <span class="body-medium">2) </span>
          <span class="label-large">REGULATION OF CEII. </span>
          <span class="body-medium"
            >Transect's Platform enables eligible users to access sensitive
            information relating to the U.S. energy infrastructure, known as
            Critical Energy/Infrastructure Information (“CEII”), such as the
            locations of electrical substations. CEII is regulated by law and
            the disclosure of CEII is restricted to only eligible persons that
            have agreed to be bound by those regulations. Violation of CEII
            regulations could result in criminal or civil sanctions. As a
            material condition of this Addendum, and Transect's sharing CEII
            with Client, Client expressly agrees to the following:</span
          >
          <ul>
            <li class="body-medium">
              Client cannot share CEII with any person or entity outside of
              Client's organization. Client may only share CEII with persons
              inside the organization that have already executed this Addendum
              (an “Authorized Recipient”).
            </li>
            <li class="body-medium">
              Client may only discuss CEII with another Authorized Recipient of
              the identical CEII. Client must first check with intended
              recipient to determine whether they are an Authorized Recipient of
              the CEII.
            </li>
            <li class="body-medium">
              Client may make hard copies of CEII, but such copies must
              conspicuously identify that CEII in contained therein. Copies of
              CEII are subject to the restrictions specified herein. Client may
              make notes concerning CEII, which shall be subject to the
              restrictions herein if such notes contain CEII.
            </li>
            <li class="body-medium">
              Client may use CEII as foundation for advice provided to others
              but may not disclose CEII to another individual who is not an
              Authorized Recipient of the identical CEII.
            </li>
            <li class="body-medium">
              Client cannot use CEII for an illegal or illegitimate purpose.
            </li>
            <li class="body-medium">
              All CEII shall be maintained by Client (and Client's organization)
              in a secure place in a manner that conforms with industry
              standards to prevent unauthorized electronic or physical access,
              and only made accessible to other Authorized Recipients of the
              CEII.
            </li>
            <li class="body-medium">
              Client must notify Transect of any unauthorized disclosure of CEII
              or any data breach or other security incident that may involve
              CEII within 3 business days of the data breach or knowledge
              thereof, and the recipient agrees to provide Transect with an
              incident management report describing Recipient's response to the
              data breach and all corrective action that has or will be taken
              within 10 business days.
            </li>
          </ul>
        </div>
        <div>
          <span class="body-medium">3) </span>
          <span class="label-large">RIGHT TO TERMINATE CEII. </span>
          <span class="body-medium"
            >Transect reserves the right to make changes to the availability of
            CEII through the Platform, or to discontinue it, at any time and
            without notice to Client.</span
          >
        </div>
        <div>
          <span class="body-medium">4) </span>
          <span class="label-large">THIRD-PARTY CLAIMS. </span>
          <span class="body-medium"
            >Transect fully disclaims all forms of liability from any
            third-party claims based on (i) violations of Paragraph 2 above; and
            (ii) any other violation of state or federal law by Client, its
            employees and agents, involving the use of CEII. As a material
            condition of this Addendum, Client agrees to indemnify and hold
            Transect harmless from all such third-party claims.</span
          >
        </div>
        <div>
          <span class="body-medium">5) </span>
          <span class="label-large">WAIVER OF LIABILITY. </span>
          <span class="body-medium"
            >IN NO EVENT SHALL TRANSECT BE LIABLE FOR ANY CLAIMS RELATING TO
            CEII PROVIDED THROUGH THE PLATFORM. This waiver shall apply
            regardless of the causes of action or legal theories that are pled
            or asserted.</span
          >
        </div>
        <div>
          <span class="body-medium">6) </span>
          <span class="label-large">TERMINATION. </span>
          <span class="body-medium"
            >This Addendum shall continue until the Agreement is terminated by
            either Party, except that the terms of Paragraphs 2, 4, 5, and 7 of
            this Addendum shall all survive termination. If Transect terminates
            the disclosure of CEII to Client for reasons other than Client's
            breach, Transect shall refund Client for any pro-rata prepayment of
            fees by Client for the CEII.</span
          >
        </div>

        <div>
          <span class="body-medium">7) </span>
          <span class="label-large">MISCELLANEOUS. </span>
        </div>
        <div>
          <span class="body-medium">a) </span>
          <span class="label-large">No Assignment. </span>
          <span class="body-medium"
            >Client may not assign any part of this Addendum to any other person
            or organization.</span
          >
        </div>
        <div>
          <span class="body-medium">b) </span>
          <span class="label-large">Amendments. </span>
          <span class="body-medium"
            >No changes or alterations to this Addendum shall be binding upon
            either Party unless executed in a writing signed by that
            Party.</span
          >
        </div>
        <div>
          <span class="body-medium">c) </span>
          <span class="label-large">Severability. </span>
          <span class="body-medium"
            >In the event any provision or provisions of this Addendum is held
            to be invalid or unenforceable by any court of law or otherwise, the
            remaining provisions of this Addendum shall nevertheless continue to
            be valid and enforceable as though the invalid or unenforceable
            parts had not been included therein.</span
          >
        </div>
        <div>
          <span class="body-medium">d) </span>
          <span class="label-large">Entire Agreement </span>
          <span class="body-medium"
            >Except for the terms and conditions of the Agreement and Client's
            quote for the CEII add-on, this Addendum constitutes the entire
            agreement of the Parties with respect to the subject matter
            contained herein, and supersedes all prior understandings,
            agreements, representations, and warranties, both written and oral,
            regarding such subject matter. To the extent of a conflict between
            the Agreement and this Addendum, the provisions of this Addendum
            shall supersede any such conflicting provisions.</span
          >
        </div>
      </div>
      <form
        class="form-container"
        [formGroup]="ceiiNdaForm"
        tsFormLoader
        [loaderState]="completing$ | async"
      >
        <div class="form-field-container">
          <span class="body-small">Name</span>
          <mat-form-field class="form-field" appearance="outline">
            <input
              matInput
              type="text"
              placeholder="Name"
              formControlName="name"
              #nameInput
              required
            />
          </mat-form-field>
        </div>

        <div class="form-field-container">
          <span class="body-small">Company Title</span>
          <mat-form-field class="form-field" appearance="outline">
            <input
              matInput
              type="text"
              placeholder="Company Title"
              formControlName="company_title"
              #companyTitleInput
              required
            />
          </mat-form-field>
        </div>

        <div class="form-field-container">
          <span class="body-small">Date</span>
          <mat-form-field class="form-field" appearance="outline">
            <input
              matInput
              [matDatepicker]="date_picker"
              placeholder="Date"
              formControlName="date"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="date_picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #date_picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="form-field-container">
          <span class="body-small">Email Address</span>
          <mat-form-field class="form-field" appearance="outline">
            <input
              type="email"
              matInput
              placeholder="Email Address"
              formControlName="email"
              required
            />
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <ng-container *ngIf="(isComplete$ | async) === false; else downloadPdf">
    <button
      transect-nx-button
      [color]="saveButtonColor$ | async"
      buttonStyle="contained"
      size="md"
      tsBtnLoader
      [loaderState]="(inProgress$ | async) === true"
      (click)="handleSaveClick()"
      [disabled]="(canSave$ | async) === false"
    >
      Yes, I agree
    </button>
    <span class="body-small text-shades-gray-dark"
      >Please fill out and scroll all the way to the bottom to save</span
    >
  </ng-container>

  <ng-template #downloadPdf>
    <button
      transect-nx-button
      buttonStyle="contained"
      size="md"
      (click)="handleDownloadPdfClick()"
      tsBtnLoader
      [loaderState]="isFetchingPdf$ | async"
    >
      Download PDF
    </button>
    <span class="date-text" *ngIf="data?.completedOn"
      >Completed on {{ data?.completedOn | date : 'MM/dd/yy' }}</span
    >
  </ng-template>
</ng-template>

import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { safeNumber } from '../../utils/string-to-number';
import { TransectPlanDTO } from '../transect-plan';

export enum CustomerMapAccess {
  ON = 'turn_on_access',
  OFF = 'turn_off_access',
}

export enum CustomMapLayersUploadAccess {
  ON = 'turn_on_access',
  OFF = 'turn_off_access',
}

export const CustomerDTO = z
  .object({
    _id: z.string().uuid(),
    account_name: z.string(),
    address_billing: z.object({}).passthrough(),
    address_mailing: z.object({}).passthrough(),
    all_collaborator: z.boolean(),
    billing_notes: z.string().nullable(),
    chargify_customer_id: z.number().nullable(),
    count_customer_users: z.number(),
    count_projects: z.number(),
    count_ordered_add_ons: z.number().nullish(),
    customer_catalog_enabled: z.boolean(),
    domain: z.string().nullable(),
    firstname: z.string(),
    subscription_custom_map_layers_upload: z
      .nativeEnum(CustomMapLayersUploadAccess)
      .default(CustomMapLayersUploadAccess.OFF)
      .optional(),
    hubspot_customer_id: z.string().nullable(),
    invoicing_allowed: z.boolean(),
    lastname: z.string(),
    name: z.string(),
    pilot_active: z.boolean(),
    pilot_start_date: DateSchema.nullable(),
    pilot_end_date: DateSchema.nullable(),
    pilot_states: z.array(z.string()),
    shared_portfolio: z.boolean(),
    shared_site_selections: z.boolean(),
    vision_enabled: z.boolean(),
    subscription_active: z.boolean(),
    subscription_start_date: DateSchema.nullable(),
    subscription_end_date: DateSchema.nullable(),
    user_seat_limit: z.number().nullable(),
    count_ordered_reports: z.number(),
    terms_accepted: z.boolean(),
    terms_accepted_date: DateSchema.nullable(),
    type: z.enum(['business', 'individual', '']),
    created_at: DateSchema,
    updated_at: DateSchema,
    deleted_at: DateSchema.nullable(),
    customer_users: z.array(
      z.object({
        role: z.enum(['admin', 'user']),
      })
    ),
    transect_plan: TransectPlanDTO.nullable(),
    users_count: z.number(),
    vision_plan__id: z.string().uuid().nullable().optional(),
    logo_gcs: z.object({}).passthrough().nullish(),
    count_instant_parcel_filter_exports: safeNumber.default(0).nullish(),
    parcel_search_export_limit: safeNumber.nullish(),
    marketplace_discount_pct: z.number().optional(),
    industry: z.preprocess((val) => {
      if (typeof val === 'string') {
        return { _id: val };
      }
      return val;
    }, z.object({ _id: z.string().uuid() }).nullish()),
  })
  .deepPartial();

export type CustomerDTO = z.infer<typeof CustomerDTO>;

<div
  class="mapboxgl-ctrl map-layer-select-container"
  [ngStyle]="absolutePosition"
  [class.map-layer-absolute-position]="!!absolutePosition"
>
  <mat-form-field floatLabel="never">
    <mat-select
      [value]="baseLayer$.getValue()"
      (selectionChange)="onBaseLayerSelectionChange($event)"
    >
      <mat-option *ngFor="let layer of baseLayers" [value]="layer.value">
        {{ layer.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

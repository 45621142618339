export const environment = {
  production: false,
  staging: true,
  rollbarPublishableKey: 'c8a889ba229b4e27bb692ce071450d8b',
  transectEnvironment: 'staging',
  mapboxToken:
    'pk.eyJ1IjoidHJhbnNlY3QtbWFzdGVyIiwiYSI6ImNrNmgwbTN0YjJveWUzbnFqNnMzdTBwaDQifQ.I8wl6wPFebh3nO15OgwiWQ',
  configcatKey: '8rfYCK2LTEKGGDL7vylRfw/S92UmwRKNEKXFJC9w6gMcw',
  STRIPE_PUBLISHABLE_KEY: 'pk_test_8kxBtwjJkts0OanqG4NmGHmj',
  ROLLBAR_PUBLISHABLE_KEY: 'c8a889ba229b4e27bb692ce071450d8b',
  ROLLBAR_SERVER_TOKEN: '9b394e0a09f145c5850ef1ffdb6b7c66',
  ROLLBAR_ENVIRONMENT: 'staging',
  TRANSECT_ENVIRONMENT: 'staging',
  GTM_ID: 'GTM-5F6DHNV',
  GTM_AUTH: 'VDnr-bmbjhWgtxaPc7vgDg',
  GTM_ENV: 'env-4',
  CONFIG_CAT_KEY: '8rfYCK2LTEKGGDL7vylRfw/S92UmwRKNEKXFJC9w6gMcw',
  HOTJAR_SITE_ID: '2366982',
  pusher: '506bc9ef1d44d6adc15b',
  apiUrl: 'https://api-staging.transect.com',
  mvtServerUrl: 'https://mvt-staging.transect.com',
  integrationsApiUrl: 'https://integrations-staging.transect.com',
  STRIPE_PUBLIC_KEY: 'pk_test_8kxBtwjJkts0OanqG4NmGHmj',
};

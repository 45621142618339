<transect-nx-dialog-modal
  title="User Questionnaire"
  [footer]="true"
  [footerTemplate]="footerTemplate"
  [contentTemplate]="contentTemplate"
>
</transect-nx-dialog-modal>
<ng-template #contentTemplate>
  <div class="main-container">
    <form
      class="form-container"
      [formGroup]="userQuestionnaireForm"
      tsDetectScrollToEnd
      (scrollEnd)="handleScrollToEnd()"
      tsFormLoader
      [loaderState]="completing$ | async"
    >
      <div class="heading">
        Environmental Site Assessments: Phase I Environmental Site Assessment
        Process
      </div>
      <div class="text-shades-black body-small">
        To qualify for Landowner Liability Protections (LLPs) under the
        Brownfields Amendments, users must complete the required inquiries
        outlined in 40 CFR 312.25-312.31. This applies to potential purchasers,
        tenants, owners, lenders, or property managers who should provide the
        necessary information to the environmental professional handling the
        Phase 1 ESA.
      </div>
      <div class="control">
        <span class="label"
          >1. Are you aware of  any environmental liens filed or recorded
          against the property under federal, tribal, state or local law?</span
        >
        <input
          transect-nx-input
          placeholder="Answer"
          formControlName="environmentalAwareness"
          required
        />
      </div>
      <div class="control">
        <span class="label">
          2. Are you aware of any land title records such as engineering
          controls, land use restrictions or institutional controls that are in
          place at the property and/or have been filed or recorded against the
          property under federal, tribal, state or local law?
        </span>
        <input
          transect-nx-input
          formControlName="landTitleRecordsAwareness"
          placeholder="Answer"
          required
        />
      </div>
      <div class="control">
        <span class="label">
          3. Do you have any specialized knowledge or experience related to the
          property or nearby properties? For example, are you involved in the
          same line of business as the current or former occupants of the
          property or an adjoining property so that you would have specialized
          knowledge of the chemicals and processes used by this type of
          business?
        </span>
        <input
          transect-nx-input
          formControlName="specializedKnowledgeOrExperience"
          placeholder="Answer"
          required
        />
      </div>
      <div class="control">
        <span class="label">
          4. Does the purchase price being paid for this property reasonably
          reflect the fair market value of the property? If you conclude that
          there is a difference, have you considered whether the lower purchase
          price is because contamination is known or believed to be present at
          the property?
        </span>
        <input
          transect-nx-input
          formControlName="reasonablePurchasePrice"
          placeholder="Answer"
          required
        />
      </div>
      <div class="control">
        <span class="label">
          5. Are you aware of commonly known or reasonably ascertainable
          information about the property that would help the environmental
          professional to identify conditions indicative of releases or
          threatened releases?  
        </span>
        <div class="sub-control-container">
          <div class="sub-control">
            <span class="label">
              a. Do you know the past uses of the property?
            </span>
            <input
              transect-nx-input
              formControlName="propertyPastUses"
              placeholder="Answer"
              required
            />
          </div>
          <div class="sub-control">
            <span class="label">
              b. Do you know of specific chemicals that are present or once were
              present at the property?
            </span>
            <input
              transect-nx-input
              formControlName="chemicalPresence"
              placeholder="Answer"
              required
            />
          </div>
          <div class="sub-control">
            <span class="label">
              c. Do you know of spills or other chemical releases that have
              taken place at the property?
            </span>
            <input
              transect-nx-input
              formControlName="chemicalSpills"
              placeholder="Answer"
              required
            />
          </div>
          <div class="sub-control">
            <span class="label">
              d. Do you know of any environmental cleanups that have taken place
              at the property?
            </span>
            <input
              transect-nx-input
              formControlName="environmentalCleanups"
              placeholder="Answer"
              required
            />
          </div>
        </div>
      </div>
    </form>
    <ts-form-marketplace-site-map
      class="map-container"
      [projectId]="data.projectId"
      [projectGeometry]="data.projectGeometry"
    ></ts-form-marketplace-site-map>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <ng-container *ngIf="(isComplete$ | async) === false; else downloadPdf">
    <button
      transect-nx-button
      [color]="saveButtonColor$ | async"
      buttonStyle="contained"
      size="md"
      (click)="handleSaveClick()"
      [disabled]="(canSave$ | async) === false"
      tsBtnLoader
      [loaderState]="inProgress$ | async"
    >
      Save
    </button>
    <span class="body-small text-shades-gray-dark"
      >Please fill out and scroll all the way to the bottom to save</span
    >
  </ng-container>

  <ng-template #downloadPdf>
    <button
      transect-nx-button
      buttonStyle="contained"
      size="md"
      (click)="handleDownloadPdfClick()"
      tsBtnLoader
      [loaderState]="isFetchingPdf$ | async"
    >
      Download PDF
    </button>
    <span class="date-text" *ngIf="data?.completedOn"
      >Completed on {{ data?.completedOn | date : 'MM/dd/yy' }}</span
    >
  </ng-template>
</ng-template>

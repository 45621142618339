import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Subject } from 'rxjs';

type State = {
  isOpen: boolean;
  template: TemplateRef<HTMLElement> | null;
  shouldCloseOnBackdropClick: boolean;
  minWidth?: string;
  maxWidth?: string;
};
@Injectable()
export class AppFlyoverStateService {
  private readonly state = new BehaviorSubject<State>({
    isOpen: false,
    template: null,
    shouldCloseOnBackdropClick: true,
  });

  private readonly closeAction = new Subject<void>();

  isOpen$ = this.state.pipe(
    map((state) => state.isOpen),
    distinctUntilChanged()
  );

  minWidth$ = this.state.pipe(
    map((state) => state.minWidth),
    distinctUntilChanged()
  );

  maxWidth$ = this.state.pipe(
    map((state) => state.maxWidth),
    distinctUntilChanged()
  );

  shouldCloseOnBackdropClick$ = this.state.pipe(
    map((state) => state.shouldCloseOnBackdropClick),
    distinctUntilChanged()
  );

  template$ = this.state.pipe(map((state) => state.template));

  /**
   * this is not state, this is just a way to expose an action to other components so that they can react to it.
     it will be fired when the backdrop is clicked, or an explicit back button is clicked
   */
  onClose$ = this.closeAction.asObservable();

  dispatchOpenFlyover(
    template: TemplateRef<HTMLElement>,
    minWidth?: string,
    maxWidth?: string,
    shouldCloseOnBackdropClick = true
  ) {
    this.updateState({
      isOpen: true,
      minWidth,
      maxWidth,
      template,
      shouldCloseOnBackdropClick,
    });
  }

  dispatchCloseFlyoverAction() {
    this.updateState({
      isOpen: false,
      template: null,
    });
    this.closeAction.next();
  }

  dispatchSetMinWidth(minWidth: string) {
    this.updateState({ minWidth });
  }

  private updateState(newState: Partial<State>) {
    this.state.next({
      ...this.state.value,
      ...newState,
    });
  }
}

import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UpgradeRequestType } from '@transect-nx/data-transfer-objects';
import { finalize, Subject, takeUntil, tap } from 'rxjs';
import { SlackMessagesService } from '../../../services/slack-messages.service';

@Component({
  selector: 'ts-upgrade-request-dialog',
  templateUrl: './upgrade-request-dialog.component.html',
  styleUrls: ['./upgrade-request-dialog.component.scss'],
})
export class UpgradeRequestDialogComponent {
  sendingRequest = false;
  requestSent = false;

  reasonForm = this.formBuilder.group({
    reason: [],
  });

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: UpgradeRequestType;
      imageUrl: string;
      description: string;
      reasonPlaceHolder: string;
    },
    private formBuilder: UntypedFormBuilder,
    private slackMessagesService: SlackMessagesService
  ) {}

  sendUpgradeRequest() {
    this.sendingRequest = true;
    const formValue = this.reasonForm.value as { reason: string };

    this.slackMessagesService
      .sendUpgradeRequest(this.data.type, formValue.reason)
      .pipe(
        tap(() => (this.requestSent = true)),
        finalize(() => (this.sendingRequest = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.reasonForm.patchValue({
          reason: '',
        });
      });
  }
}

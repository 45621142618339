import { ComponentRef } from '@angular/core';
import { IControl, Map, Style } from 'mapbox-gl';
import { BehaviorSubject, Observable } from 'rxjs';
import { TransectMap } from '../classes/TransectMap';
import { BaseLayerControlComponent } from '../components/base-layer-control/base-layer-control.component';
import { DynamicComponentService } from '../services/dynamic-component.service';
import { MapService } from '../services/map.service';

export type BaseLayerOptions = {
  absolutePosition?: {
    top?: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;
  };
  isReportMap?: boolean;
};

export class BaseLayerControl implements IControl {
  private key: number;
  private dynamicComponentService: DynamicComponentService;
  private absolutePosition?: {
    top?: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;
  };
  private baseLayer$: BehaviorSubject<string | Style>;
  componentRef: ComponentRef<BaseLayerControlComponent>;

  /**
   * @deprecated
   * Do not directly create an instance of BaseLayerControl.
   * Use the MapService method addBaseLayerControl$ instead.
   */
  constructor(
    dynamicComponentService: DynamicComponentService,
    options: BaseLayerOptions = {}
  ) {
    this.dynamicComponentService = dynamicComponentService;
    this.absolutePosition = options.absolutePosition;
  }

  get currentBaseLayer(): string | Style {
    return this.componentRef.instance.baseLayer$.getValue();
  }

  waitForControlReady$(): Observable<void> {
    return this.componentRef.instance.waitForControlReady$;
  }

  onAdd(map: TransectMap): HTMLElement {
    const { element, key, componentRef } =
      this.dynamicComponentService.injectComponent(
        BaseLayerControlComponent,
        (component) => {
          component.transectMap$.next(map);
          component.baseLayer$ = this.baseLayer$
            ? this.baseLayer$
            : new BehaviorSubject(map.initialStyle);
          component.absolutePosition = this.absolutePosition;
        }
      );

    this.key = key;
    this.componentRef = componentRef;
    return element;
  }

  onRemove(map: Map): any {
    this.dynamicComponentService.destroy(this.key);
  }

  getDefaultPosition(): string {
    return 'top-left';
  }
}

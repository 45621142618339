import { Component } from '@angular/core';
import { AppFlyoverStateService } from './app-flyover-state.service';

@Component({
  selector: 'ts-app-flyover',
  templateUrl: './app-flyover.component.html',
  styleUrls: ['./app-flyover.component.scss'],
})
export class AppFlyoverComponent {
  isOpen$ = this.appFlyoverStateService.isOpen$;

  minWidth$ = this.appFlyoverStateService.minWidth$;

  maxWidth$ = this.appFlyoverStateService.maxWidth$;

  template$ = this.appFlyoverStateService.template$;

  shouldCloseOnBackdropClick$ =
    this.appFlyoverStateService.shouldCloseOnBackdropClick$;

  constructor(
    private readonly appFlyoverStateService: AppFlyoverStateService
  ) {}

  handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      this.appFlyoverStateService.dispatchCloseFlyoverAction();
    }
  }

  handleBackdropClicked() {
    this.appFlyoverStateService.dispatchCloseFlyoverAction();
  }
}

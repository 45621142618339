import { z } from 'zod';
import {
  pageNumber,
  pageSize,
  sortModel,
} from '../../../utils/paginated-response-rows';
import { SolarRegulationType } from '../solar-regulation-dto';
export const SolarRegulationsIndexParams = z.object({
  sortModel: z
    .preprocess(
      (val) => (typeof val === 'string' ? JSON.parse(val) : val),
      sortModel
    )
    .optional(),
  pageSize: pageSize,
  page: pageNumber,
  search: z.string().nullish(),
  regulationType: z.nativeEnum(SolarRegulationType),
});
export type SolarRegulationsIndexParams = z.infer<
  typeof SolarRegulationsIndexParams
>;

import { z } from 'zod';
import { Sentiment } from '../../utils';
import { DateSchema } from '../../utils/date-schema';

export const SolarSentimentNewsDTO = z.object({
  _id: z.string().uuid().nullish(),
  title: z.string().nullish(),
  favorability: z.number(),
  sentiment: Sentiment,
  url: z.string().nullish(),
  summary: z.string().nullish(),
  thumbnail: z.string().nullish(),
  published: DateSchema.nullish(),
  county: z.string(),
  state: z.string(),
  fav_reason: z.string().nullish(),
  publication_name: z.string().nullish(),
});

export type SolarSentimentNewsDTO = z.infer<typeof SolarSentimentNewsDTO>;

export const SolarSentimentNewsCountyDTO = z.object({
  name: z.string(),
  id: z.string().uuid().nullish(),
  solar_sentiment_news_data: z.array(SolarSentimentNewsDTO),
  sentiment_score: z.number(),
  sentiment: Sentiment,
});

export const SolarSentimentNewsItemsDTO = z.array(
  z.object({
    state: z.string(),
    state_abbreviation: z.string(),
    geography__id: z.string(),
    counties: z.array(SolarSentimentNewsCountyDTO),
  })
);

export type SolarSentimentNewsItemsDTO = z.infer<
  typeof SolarSentimentNewsItemsDTO
>;

<transect-nx-dialog-modal
  title="Marketplace Agreement"
  [footer]="true"
  [footerTemplate]="footerTemplate"
  [contentTemplate]="contentTemplate"
>
</transect-nx-dialog-modal>
<ng-template #contentTemplate>
  <div
    class="scrollable-content"
    tsDetectScrollToEnd
    (scrollEnd)="handleScrollToEnd()"
  >
    <div class="content-container">
      <div class="agreement-content text-shades-black">
        <div class="mb-20">
          <span class="title-medium">MARKETPLACE SERVICES AGREEMENT</span>
        </div>
        <div>
          <span class="body-medium"
            >This MARKETPLACE SERVICES AGREEMENT (“Agreement”) is made between
            Transect, Inc., a Delaware for-profit corporation (“Transect”), and
            the signatory to this Agreement (“Client”) as of the date of
            Client’s signature. Transect and Client are also referred to
            individually as a “Party” and collectively as the “Parties.” </span
          >
        </div>
        <div>
          <span class="label-large">WHEREAS, </span>
          <span class="body-medium"
            >Transect is in the business of providing its Marketplace customers
            with access to professional consultants who can issue certain
            reports, surveys, assessments, and/or summaries relating to specific
            real property (“Consultants”); and</span
          >
        </div>
        <div>
          <span class="label-large">WHEREAS, </span>
          <span class="body-medium"
            >Client desires that Transect provide access to Consultants and
            their services;</span
          >
        </div>
        <div>
          <span class="label-large">NOW, THEREFORE, </span>
          <span class="body-medium"
            >in consideration of the mutual promises set forth in this
            Agreement, the adequacy and sufficiency of which are hereby
            acknowledged, the Parties agree as follows:</span
          >
        </div>
        <div>
          <div>
            <span class="body-medium">1) </span>
            <span class="label-large">MARKETPLACE SERVICES</span>
          </div>
          <span class="body-medium"
            >Subject to the terms of this Agreement, Transect agrees to provide
            Client with trained Consultants that can render all the services,
            materials, and deliverables necessary to fulfill each Service Quote
            ordered by Client through Transect’s Marketplace (the “Services”).
            Transect’s Consultants shall perform the Services in accordance with
            this Agreement.</span
          >
        </div>
        <div>
          <div>
            <span class="body-medium">2) </span>
            <span class="label-large">SERVICE REQUESTS</span>
          </div>
          <span class="body-medium"
            >When requesting a service through the Marketplace, Client must
            provide certain information to Transect, including Client’s contact
            information and details about the applicable real property. Once
            completed, the requested service and corresponding information will
            be submitted to Transect for its review (hereinafter a “Service
            Request”). Upon receipt of a Service Request, Transect shall have up
            to three (3) business days to provide Client with a quote (a
            “Service Quote”) for the price of the requested service and to
            confirm the availability of a Consultant. In some circumstances
            (typically when multiple services have been simultaneously
            requested), Transect may choose to itemize and price each individual
            service within a Service Quote. If Transect is unable to secure a
            Consultant within three business days of a Service Request, Transect
            may either notify Client that a Service Quote cannot be provided or
            may request an extension of time. Notwithstanding the foregoing,
            Transect shall have no obligation to provide a Service Quote or
            secure a Consultant for Client, and Transect may elect to decline
            any Service Request at its discretion.</span
          >
        </div>
        <div>
          <span class="body-medium">3) </span>
          <span class="label-large">CONSULTANT ASSIGNMENT. </span>
          <span class="body-medium"
            >After receiving a Service Quote, Client shall have five (5)
            business days to order the Services at the quoted price(s) or the
            Service Quote shall automatically expire. Upon Client’s timely
            order, Transect will assign the Consultant(s) to perform the
            Services.</span
          >
        </div>
        <div>
          <span class="body-medium">4) </span>
          <span class="label-large">CHANGE IN SERVICES. </span>
          <span class="body-medium"
            >This Agreement prohibits the use of change orders for any Services
            provided to Client by Consultants, unless expressly approved by
            Transect in writing and in advance. After a Service Quote has been
            ordered, if either Party (or a Consultant) determines that
            additional services are necessary or desirable for Client’s intended
            purpose, then Transect shall notify Client and recommend the
            submission of a new Service Request for such services. A change in
            the scope of Services can only be achieved through the approval of a
            new Service Quote. Client is prohibited from entering into an
            agreement directly with Consultant for any additional services,
            unless specifically approved in writing by Transect in advance.
            Under no circumstance shall the previously assigned Consultant be
            required to provide additional services to the Client, nor shall
            Transect be required to offer new Service Requests to the previously
            assigned Consultant. </span
          >
        </div>
        <div>
          <div>
            <span class="body-medium">5) </span>
            <span class="label-large">SITE INSPECTIONS. </span>
            <span class="body-medium"
              >Some of the Marketplace services offered by Transect, such as
              Phase 1 Environmental Site Assessments, require that a Consultant
              physically inspect the applicable land. For purposes of this
              Agreement, the “Site” shall be limited to the premises upon which
              the Client has requested the Services to be performed, the
              description of which must be provided within Client’s Service
              Request. Prior to any Site inspection, Client must do the
              following: (i) Disclose to Transect the identity of the owner
              and/or operator of the Site, and provide their contact
              information; (ii) Review, complete, and sign Transect’s Site
              Inspection Authorization Form; and (iii) Submit Transect’s Site
              Questionnaire (Phase 1 ESAs only). Thereafter, the assigned
              Consultant will coordinate the date and time of the Site
              inspection with the owner/operator of the Site. </span
            >
          </div>
          <div>
            <span class="body-medium"
              >a) Hazards. Client must provide all relevant information about
              the Site in the Site Questionnaire, including any areas of
              concern, potential hazards, or conditions at the Site which might
              present a threat to human health and safety. Client expressly
              understands and agrees that Client has a continued obligation to
              promptly notify Transect if any such information is learned or
              discovered, both before and after any Site inspection. </span
            >
          </div>
          <div>
            <span class="body-medium"
              >b) Damage to Site. Consultant will take reasonable measures to
              limit damage to the Site, but Client expressly understands that
              some damage may occur during the normal course of Services. Client
              agrees that the correction of such damage is not Transect’s
              responsibility and must be undertaken by Client at Client’s sole
              expense. Additionally, Client is solely responsible for the
              accuracy of locations for all subterranean structures and
              utilities on the Site. Client agrees to waive any claim against
              Transect and/or Consultant for injury or loss arising from damage
              done to subterranean structures and utilities which were not
              sufficiently identified by Client, and further agrees to indemnify
              Transect and/or Consultant from such injury or loss. </span
            >
          </div>
        </div>
        <div>
          <span class="body-medium">6) </span>
          <span class="label-large">OPINIONS OF COST. </span>
          <span class="body-medium"
            >This Agreement does not include any order-of-magnitude opinions or
            estimates of costs for remediation or construction. Client
            understands that Consultants are prohibited from issuing opinions of
            cost and Transect disclaims any such opinions that are made by a
            Consultant. </span
          >
        </div>
        <div>
          <span class="body-medium">7) </span>
          <span class="label-large">PAYMENT TO TRANSECT. </span>
          <span class="body-medium"
            >For any Services ordered by Client that have an estimated
            completion date (in their Service Quote) of thirty (30) days or
            less, Client shall pay Transect the agreed Service Quote price
            within fifteen days after the completion of the Services, the
            delivery of any Report(s) to Client, and the delivery of Transect’s
            invoice. For any Services ordered by Client that have an estimated
            completion date of more than thirty days, Client shall pay Transect
            within fifteen days after the delivery of Transect’s invoice for the
            services completed by Consultants each month. For any Services
            ordered by Client that have been specifically itemized within a
            Service Quote, Client shall pay Transect the agreed item price
            within fifteen days of Client’s receipt of Transect’s invoice for
            each completed item of service. Sales tax shall be added to the
            Services, when applicable.</span
          >
        </div>
        <div>
          <span class="body-medium">a) </span>
          <span class="label-large">Invoice Objections. </span>
          <span class="body-medium"
            >If Client objects any portion of an invoice, Client shall notify
            Transect in writing within five (5) business days of receiving the
            invoice, identify the cause of disagreement, and pay within fifteen
            (15) days that portion of the invoice not in dispute. The Parties
            shall make every effort to settle the disputed portion. In the
            absence of written notification to Transect as described above,
            Client shall pay the full balance stated on the invoice. </span
          >
        </div>
        <div>
          <span class="body-medium">b) </span>
          <span class="label-large">Failure to Pay. </span>
          <span class="body-medium"
            >Invoices are delinquent if payment has not been received within
            fifteen (15) days from the date the invoice is received. If Client
            fails to fully pay any Transect invoices within fifteen days of
            receipt, Transect may immediately suspend all Services and demand
            that Client return any documents, documents, surveys, assessments,
            recommendations, findings, letters, drawings, and specifications
            (collectively “documents”) which are created, furnished, or produced
            by Transect and/or Consultants, and which have not been fully paid
            for by Client. Client agrees that any document or other work
            furnished to Client or Client’s agents, for which full payment has
            not been made to Transect, will be returned to Transect upon demand
            and will not be used by Client for any purpose or disseminated to
            any third party by Client. It is agreed that any claims relating to
            Client’s liabilities, losses, expenses, costs, fees and the like (of
            every kind and nature whatsoever), which result directly or
            indirectly from Transect’s suspension of services (and/or demand for
            return of documents) following Client’s failure to pay an invoice in
            full, are irrevocably waived by Client and may not be asserted in
            any mediation or litigation. </span
          >
        </div>
        <div>
          <span class="body-medium">c) </span>
          <span class="label-large">Payment After Termination. </span>
          <span class="body-medium"
            >If this Agreement is terminated by Client prior to the completion
            of a non-itemized Service Quote, Client shall pay Transect the full
            amount of that Service Quote if a Consultant began performing any
            services prior to the date of termination (and regardless of whether
            such services were completed). If this Agreement is terminated by
            Client prior to the completion of an itemized Service Quote, Client
            shall pay for each item that a Consultant began servicing prior to
            termination. In either event, Transect will provide Client with an
            invoice detailing the fees owed for such Services and Client must
            pay that invoice with fifteen days of receipt. Upon Client’s payment
            of Transect’s final invoice, Transect shall provide and turn over to
            Client all environmental data and analyses prepared prior to
            Client’s termination of the Agreement. </span
          >
        </div>
        <div>
          <span class="body-medium">8) </span>
          <span class="label-large">TERMINATION. </span>
          <span class="body-medium"
            >This Agreement will become effective when signed by Client and will
            continue until terminated by either Party in writing. </span
          >
        </div>
        <div>
          <span class="body-medium">9) </span>
          <span class="label-large">INSURANCE. </span>
          <span class="body-medium"
            >Transect and its Consultants shall maintain such insurance policies
            to sufficiently cover the Services rendered under this
            Agreement. </span
          >
        </div>
        <div>
          <span class="body-medium">10) </span>
          <span class="label-large">CONFIDENTIALITY. </span>
          <span class="body-medium"
            >Transect acknowledges that it will receive, and have access to,
            information that is treated as confidential by Client. Transect
            agrees not to intentionally disclose (or permit to be disclosed) any
            confidential information to any third-party person, company, or
            organization, except as required in the performance of the Services
            or as directed by Client. However, nothing herein shall be construed
            to prevent disclosure of information that is required by applicable
            law, or regulation, or pursuant to the valid order of a court or an
            authorized government agency, provided that it does not exceed the
            extent of disclosure required by such law, regulation, or
            order. </span
          >
        </div>
        <div>
          <span class="body-medium">11) </span>
          <span class="label-large">LICENSING OF WORK PRODUCTS. </span>
          <span class="body-medium"
            >Client hereby grants permission for Transect and Consultants to use
            information and data provided by Client, including electronic
            records produced or provided by Client, during the scope of the
            Services. Client also grants Transect and Consultants permission to
            release Client’s documents electronically to other consultants,
            contractors, vendors, or persons necessary to complete or assist
            with the Services. Upon Client’s submission of a Service Request,
            Client shall automatically grant a license to Transect and
            Consultants for the use of all work products and materials during
            the performance of Services for Client. Nevertheless, all work
            products and materials (including surveys, assessments,
            recommendations, findings, and other documents that are created
            and/or produced under this Agreement) which have been paid for by
            Client shall remain the property of Client.  </span
          >
        </div>
        <div>
          <span class="body-medium">12) </span>
          <span class="label-large">THIRD-PARTY RELIANCE. </span>
          <span class="body-medium"
            >All documents, surveys, assessments, recommendations,
            findings, letters, drawings, and specifications (collectively
            “documents”) which are created, furnished, or produced by Transect
            and/or Consultants under this Agreement are instruments of their
            service. Such documents are not intended to be relied upon by any
            third-party persons or organizations. Transect fully disclaims all
            forms of liability from any third-party reliance claims, and Client
            agrees to indemnify and hold Transect harmless from such third-party
            claims.</span
          >
        </div>
        <div>
          <span class="body-medium">13) </span>
          <span class="label-large">LIMITED WARRANTY. </span>
          <span class="body-medium"
            >Transect shall endeavor for its Consultants to furnish accurate
            reports, surveys, assessments, and/or summaries, which are free from
            material errors or omissions. Transect requires its Consultants to
            perform Services consistent with the level of care and skill
            ordinarily exercised by other professional consultants in the same
            locale and under similar circumstances at the same time the Services
            are performed. Transect hereby offers a Limited Warranty on Services
            for a period of one (1) year after the completion of those Services
            for Client. To the extent discovered within one (1) year from
            completion of Services performed under a Service Quote, Transect
            shall promptly correct any known or discovered error, omission, or
            other defect without any additional cost or expense to Client. If
            any part of Transect’s (or Consultant’s) Services is found to be
            defective within one year of completion, due to Transect’s (or
            Consultant’s) own errors or omissions, Transect’s entire liability
            for such defective work shall be to re-perform, at its own expense,
            those aspects of the Services which were found to be defective.
            However, Client must notify Transect in writing as soon as the
            defect is discovered and within the one-year warranty period
            outlined in this paragraph. </span
          >
        </div>
        <div>
          <span class="body-medium"
            >EXCEPT FOR THE LIMITED WARRANTY IN THIS SECTION, TRANSECT MAKES NO
            WARRANTY WHATSOEVER WITH RESPECT TO ITS SERVICES, INCLUDING ANY (A)
            WARRANTY OF MERCHANTABILITY; (B) WARRANTY OF FITNESS FOR A
            PARTICULAR PURPOSE; OR (C) WARRANTY OF TITLE; WHETHER EXPRESS OR
            IMPLIED BY LAW, AND THE SAME ARE EXPRESSLY WAIVED AND DISCAIMED BY
            CLIENT. </span
          >
        </div>
        <div>
          <span class="body-medium">14) </span>
          <span class="label-large">INDEMNIFICATION. </span>
          <span class="body-medium"
            >Client shall defend, hold harmless, and indemnify Transect and its
            officers, directors, employees, contractors, agents, affiliates,
            successors, and assigns from and against all losses, damages,
            liabilities, deficiencies, lawsuits, actions, claims, judgments,
            interest, awards, penalties, fines, costs, or expenses of whatever
            kind (including attorneys’ fees) which arise out of or result from
            (i) bodily injury or death of any person due, at least in part, to
            Client’s acts, omissions, or conduct; (ii) damage to real or
            personal property due, at least in part, to Client’s acts,
            omissions, or conduct; or (iii) the performance of Services, when
            attributable to either a breach by Client of its obligations
            hereunder or the acts, omissions, or conduct of Client (or anyone
            acting under Client’s direction or control). </span
          >
        </div>
        <div>
          <span class="body-medium"
            >Notwithstanding the Limited Warranty on Services above, it is
            expressly agreed that Transect may rely upon information provided by
            Client (or by third parties on behalf of Client) without any duty to
            independently verify the correctness or accuracy of such
            information. Client agrees to indemnify, defend and hold Transect
            harmless from and against liabilities arising from the inaccuracy or
            incorrect information in such Client-provided information (if
            any). </span
          >
        </div>
        <div>
          <span class="body-medium">15) </span>
          <span class="label-large">LIMITATION OF LIABILITY. </span>
          <span class="body-medium"
            >IN NO EVENT SHALL TRANSECT BE LIABLE TO CLIENT OR TO ANY THIRD
            PARTY FOR ANY LOSS OF USE, REVENUE, PROFIT, OR BUSINESS, OR FOR ANY
            CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE
            DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT OR WARRANTY, TORT
            (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, REGARDLESS OF
            WHETHER SUCH DAMAGE WAS FORESEEABLE, </span
          >
          <div>
            <span class="body-medium"
              >EXCEPT IF SUCH DAMAGES RESULT FROM TRANSECT’S WILLFUL
              MISCONDUCT.</span
            >
          </div>
        </div>
        <div>
          <span class="body-medium"
            >In other sections of this Agreement, Client has agreed to waive
            specific claims against Transect and this limitation section shall
            not operate or be construed as allowing anything other than a
            complete waiver of such claims.</span
          >
        </div>
        <div>
          <span class="body-medium"
            >IN NO EVENT SHALL TRANSECT’S AGGREGATE LIABILITY ARISING OUT OF OR
            RELATED TO THIS AGREEMENT (OR ANY AMENDMENTS TO THIS AGREEMENT),
            WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT
            (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, EXCEED THE
            AGGREGATE AMOUNTS PAID BY CLIENT TO TRANSECT PURSUANT TO THIS
            AGREEMENT.
          </span>
        </div>
        <div>
          <span class="body-medium"
            >This allocation of risk shall apply regardless of the causes of
            action or legal theory, plead or asserted. If Client prefers not to
            limit Transect’s professional liability to the aggregate amounts
            paid by Client, Transect will adjust this limitation upon Client's
            written request, provided that Client agrees to pay for this waiver
            at a negotiated fee that is approved in writing by Transect.
            Client's request for this option must be made before Client submits
            a Service Request for which Client desires a new liability
            limitation to apply.</span
          >
        </div>
        <div>
          <span class="body-medium">16) </span>
          <span class="label-large">MISCELLANEOUS </span>
        </div>
        <div>
          <span class="body-medium">a) </span>
          <span class="label-large">Retention of Documents. </span>
          <span class="body-medium"
            >Transect shall not be obligated to maintain any written documents,
            products, materials, or electronic files relating to Client for more
            than two (2) years following completion of Services. </span
          >
        </div>
        <div>
          <span class="body-medium">b) </span>
          <span class="label-large">Assignment. </span>
          <span class="body-medium"
            >Client may not assign or delegate any of its rights or duties under
            this Agreement without the express and prior written consent of
            Transect. However, Transect may assign any ordered Services to the
            Consultants of its choosing without Client’s prior consent. </span
          >
        </div>
        <div>
          <span class="body-medium">c) </span>
          <span class="label-large">Force Majeure. </span>
          <span class="body-medium"
            >Except for the payment of the Services already performed, neither
            Party shall be responsible for any delay in performance or failure
            to meet its obligations under this Agreement to the extent caused,
            directly or indirectly, by the other Party’s failure to reasonably
            cooperate, or any event beyond the performing Party’s reasonable
            control including, but not limited to fire, flood, severe weather,
            earthquake, pandemic, strike, embargo, war, or any other acts of
            god. </span
          >
        </div>
        <div>
          <span class="body-medium">d) </span>
          <span class="label-large">Amendments. </span>
          <span class="body-medium"
            >No changes or alterations to this Agreement shall be binding upon
            either Party unless executed in a writing signed by that
            Party. </span
          >
        </div>
        <div>
          <span class="body-medium">e) </span>
          <span class="label-large">Severability. </span>
          <span class="body-medium">
            In the event any provision or provisions of this Agreement is held
            to be invalid or unenforceable by any court of law or otherwise, the
            remaining provisions of this Agreement shall nevertheless continue
            to be valid and enforceable as though the invalid or unenforceable
            parts had not been included therein.
          </span>
        </div>
        <div>
          <span class="body-medium">f) </span>
          <span class="label-large">Waiver Reservation. </span>
          <span class="body-medium">
            The waiver by either Party of a breach of any provision of this
            Agreement shall not operate or be construed as a waiver of any other
            breach. 
          </span>
        </div>
        <div>
          <span class="body-medium">g) </span>
          <span class="label-large">Governing Law. </span>
          <span class="body-medium">
            This Agreement shall be governed by the laws of the State of Texas
            without regard to its conflicts of laws principles.  
          </span>
        </div>
        <div>
          <span class="label-large">MARKETPLACE SERVICES AGREEMENT </span>
        </div>
        <div>
          <span class="body-medium">h) </span>
          <span class="label-large">Venue for Disputes. </span>
          <span class="body-medium">
            If a dispute or claim arises out of this Agreement or any amendments
            to this Agreement, the Parties will attempt to settle the dispute
            between each other. If unsuccessful, the Parties agree to settle any
            such dispute or claim through mediation offered by the American
            Arbitration Association (AAA). The mediation shall be held within 30
            days of the demand for mediation, and costs of the mediation shall
            be borne equally by the parties. Notwithstanding anything to the
            contrary above, the Parties agree that the mediation proceedings
            shall be held in Travis County, Texas.  
          </span>
        </div>
        <div>
          <span class="body-medium">
            If the Parties are unable to resolve the matter through mediation,
            absent any contrary agreement in writing between the parties, either
            party may institute litigation in a court of law, and it is agreed
            that no offers, communications, presentations or evidence made or
            presented in the course of the mediation shall be admissible in the
            litigation. Any lawsuits that arise from this Agreement shall be
            litigated in the state or federal courts in Travis County, Texas.  
          </span>
        </div>
        <div>
          <div>
            <span class="body-medium">i) </span>
            <span class="label-large">Entire Agreement </span>
          </div>

          <span class="body-medium">
            This Agreement constitutes the entire agreement of the Parties with
            respect to the subject matter contained herein, and supersedes all
            prior and contemporaneous understandings, agreements,
            representations, and warranties, both written and oral, regarding
            such subject matter. Nothing under this Agreement shall be construed
            to give any rights or benefits in this Agreement to anyone other
            than Client and Transect, and all duties and responsibilities
            undertaken pursuant to this Agreement are for the sole and exclusive
            benefit of Client and Transect and not for the benefit of any other
            party.  
          </span>
        </div>
        <div>
          <span class="body-medium">
            IN WITNESS WHEREOF, Transect has offered and approved this
            Agreement, and Client now executes this Agreement through its
            authorized representative. 
          </span>
        </div>
      </div>
      <form
        class="form-container"
        [formGroup]="marketplaceAgreementForm"
        tsFormLoader
        [loaderState]="completing$ | async"
      >
        <div class="form-field-container">
          <span class="body-small">Name</span>
          <mat-form-field class="form-field" appearance="outline">
            <input
              matInput
              type="text"
              placeholder="Name"
              formControlName="name"
              #nameInput
              required
            />
          </mat-form-field>
        </div>

        <div class="form-field-container">
          <span class="body-small">Company Title</span>
          <mat-form-field class="form-field" appearance="outline">
            <input
              matInput
              type="text"
              placeholder="Company Title"
              formControlName="company_title"
              #companyTitleInput
              required
            />
          </mat-form-field>
        </div>

        <div class="form-field-container">
          <span class="body-small">Date</span>
          <mat-form-field class="form-field" appearance="outline">
            <input
              matInput
              [matDatepicker]="date_picker"
              placeholder="Date"
              formControlName="date"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="date_picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #date_picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="form-field-container">
          <span class="body-small">Email Address</span>
          <mat-form-field class="form-field" appearance="outline">
            <input
              type="email"
              matInput
              placeholder="Email Address"
              formControlName="email"
              required
            />
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <ng-container *ngIf="(isComplete$ | async) === false; else downloadPdf">
    <button
      transect-nx-button
      [color]="saveButtonColor$ | async"
      buttonStyle="contained"
      size="md"
      tsBtnLoader
      [loaderState]="(inProgress$ | async) === true"
      (click)="handleSaveClick()"
      [disabled]="(canSave$ | async) === false"
    >
      Yes, I agree
    </button>
    <span class="body-small text-shades-gray-dark"
      >Please fill out and scroll all the way to the bottom to save</span
    >
  </ng-container>

  <ng-template #downloadPdf>
    <button
      transect-nx-button
      buttonStyle="contained"
      size="md"
      (click)="handleDownloadPdfClick()"
      tsBtnLoader
      [loaderState]="isFetchingPdf$ | async"
    >
      Download PDF
    </button>
    <span class="date-text" *ngIf="data?.completedOn"
      >Completed on {{ data?.completedOn | date : 'MM/dd/yy' }}</span
    >
  </ng-template>
</ng-template>
